var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"e6so2jfLqTJgzGZWKjMfn"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from '@sentry/nextjs';
import { isLocal, isTest, getEnvByHostname } from 'componentes/apiHelpers/env';
import PACKJSON from './package.json';

console.log('---------------------- [sentry.server.config]------------------', PACKJSON.version);

if (!isLocal() && !isTest() && window) {
console.log('------------------ [call sentry.init with] ------------------');

Sentry.init({
  dsn: "https://5439134a9a9c4ee88a062d48f344a55d@o1055295.ingest.us.sentry.io/6710026",
  environment: getEnvByHostname(window.location.hostname),
  // Add optional integrations for additional features
  integrations: [
    // TODO Enable when we upgrade to SentrySDK 8 (requires NextJS 13 or higher)
    // Sentry.replayIntegration(),
    Sentry.browserTracingIntegration(),
  ],
  
  // Define how likely traces are sampled. Adjust this value in production, or use tracesSampler for greater control.
  tracesSampleRate: getEnvByHostname(window.location.hostname) === 'production' ? 0.1 : 0.5,

  // Define how likely Replay events are sampled.
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // Define how likely Replay events are sampled when an error occurs.
  replaysOnErrorSampleRate: 0.1,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,
});
}
