import { defineMessages } from 'react-intl';

export default defineMessages({
  customerCanceled: {
    id: 'customer_canceled',
    defaultMessage: 'I dont want the order',
  },
  invalidData: {
    id: 'invalid_data',
    defaultMessage: 'Order error',
  },
  dropoffClosedPoint: {
    id: 'dropoff_closed_point',
    defaultMessage: 'Dropoff closed point',
  },
});
