import { actualTotal } from "services/cart"
import formatNumber from "services/formatNumber"
import nameunitmeasure from "services/nameunitmeasure"
import { customerExperiencePhone } from "services/helpers"
import getcommunityleader from "services/getcommunityleader"
import getcode from "services/getcode"
import { featureMarkAsDelivered } from "services/featureflags"

const getDetail = (cart, lines) => {
    let detail = ''
    cart.map((singleCart, index) => {
        if(index < cart.length - 1) {
            detail = detail + `${singleCart.cantidad} ${nameunitmeasure(singleCart.datos_producto.unit_of_measurement.erp_name)} x ${singleCart.nombre} *$${formatNumber(actualTotal(singleCart.productid, lines))}*  |  `
        } else {
            detail = detail + `${singleCart.cantidad} ${nameunitmeasure(singleCart.datos_producto.unit_of_measurement.erp_name)} x ${singleCart.nombre} *$${formatNumber(actualTotal(singleCart.productid, lines))}*`
        }
    })
    return detail
}

const parseData = (order, client, communityLeader, detail, slug) => {
    const data = {
        name: client.nombre,
        orderCode: order.code,
        date: `${order.created_at.slice(8, 10)}/${order.created_at.slice(5, 7)}/${order.created_at.slice(0, 4)}`,
        time: `${parseInt(order.created_at.slice(11, 13)) - 6}:${parseInt(order.created_at.slice(14, 16))}`,
        deliveryDate: `${order.selected_delivery_date.day}/${order.selected_delivery_date.month}`,
        shopName: order.shop_name,
        shopPhone: communityLeader.phone,
        orderValue: order.sum_of_line_totals.value,
        total: order.total.value,
        paymentMethod: client.payment_method || '-',
        serviceCharge: Number(order?.service_charge_value.value) ?? null,
        couponValue: order.discount_value.value ?? null,
        detail,
        slug,
        delivery_address_main_line: order.delivery_address_main_line,
        countryId: order.country_id
    }
    return data
}

const getMessageOrderConfirmationToClient = (data, countryId, hasCoupon, hasServiceCharge, deliveryPoint, leader_name, leader_phone) => {
    let message = ''
    if(!deliveryPoint && !data?.code && countryId !== 46 && countryId !== 95) {
        if(hasCoupon && hasServiceCharge) {
            switch(countryId) {
                case 1:
                    message = `Hola ${data.name}, ¡gracias por comprar en el Mercadito ${data.shopName}! 📦\n\n 🛒 Tu pedido número ${data.orderCode}, realizado el ${data.date} se ha procesado con éxito, podés retirarlo el ${data.deliveryDate}. \n\n💲 *Total*\nValor de la compra: $${data.orderValue}\n🎉 *Descuento cupón: -$${data.couponValue}*\nTasa de servicio: $${data.serviceCharge}\nTotal a pagar: $*${data.total}*\nMétodo de pago: ${data.paymentMethod}\n\n📝 *Detalle del pedido*\n${data.detail}\n\nGracias, que lo disfrutes 🥳`
                    break
                case 2:
                    message = `Hola ${data.name}, ¡gracias por comprar en el Mercadito ${data.shopName}! 📦\n\n 🛒 Tu pedido número ${data.orderCode}, realizado el ${data.date} se ha procesado con éxito, puedes retirarlo el ${data.deliveryDate}. \n\n💲 *Total*\nValor de la compra: $${data.orderValue}\n🎉 *Descuento cupón: -$${data.couponValue}*\nTasa de servicio: $${data.serviceCharge}\nTotal a pagar: $*${data.total}*\nMétodo de pago: ${data.paymentMethod}\n\n📝 *Detalle del pedido*\n${data.detail}\n\nGracias, que lo disfrutes 🥳`
                    break
                default:
                    message = `Hola ${data.name}, ¡gracias por comprar en el Mercadito ${data.shopName}! 📦\n\n 🛒 Tu pedido número ${data.orderCode}, realizado el ${data.date} se ha procesado con éxito, puedes retirarlo el ${data.deliveryDate}. \n\n💲 *Total*\nValor de la compra: $${data.orderValue}\n🎉 *Descuento cupón: -$${data.couponValue}*\nTasa de servicio: $${data.serviceCharge}\nTotal a pagar: $*${data.total}*\nMétodo de pago: ${data.paymentMethod}\n\n📝 *Detalle del pedido*\n${data.detail}\n\nGracias, que lo disfrutes 🥳`
                    break
            }
        } else if(hasCoupon && !hasServiceCharge) {
            // ok
            switch(countryId) {
                case 1:
                    message = `Hola ${data.name}, ¡gracias por comprar en el Mercadito ${data.shopName}! 📦\n\n🛒 Tu pedido número ${data.orderCode}, realizado el ${data.date} se ha procesado con éxito, podés retirarlo el ${data.deliveryDate}. \n\n💲 *Total*\nValor de la compra: $${data.orderValue}\n🎉 *Descuento cupón: -$${data.couponValue}*\nTotal a pagar: $*${data.total}*\nMétodo de pago: ${data.paymentMethod}\n\n📝 *Detalle del pedido*\n${data.detail}\n\nGracias, que lo disfrutes 🥳`
                    break
                case 2:
                    message = `Hola ${data.name}, ¡gracias por comprar en el Mercadito ${data.shopName}! 📦\n\n🛒 Tu pedido número ${data.orderCode}, realizado el ${data.date} se ha procesado con éxito, puedes retirarlo el ${data.deliveryDate}. \n\n💲 *Total*\nValor de la compra: $${data.orderValue}\n🎉 *Descuento cupón: -$${data.couponValue}*\nTotal a pagar: $*${data.total}*\nMétodo de pago: ${data.paymentMethod}\n\n📝 *Detalle del pedido*\n${data.detail}\n\nGracias, que lo disfrutes 🥳`
                    break
                default:
                    message =  `Hola ${data.name}, ¡gracias por comprar en el Mercadito ${data.shopName}! 📦\n\n🛒 Tu pedido número ${data.orderCode}, realizado el ${data.date} se ha procesado con éxito, puedes retirarlo el ${data.deliveryDate}. \n\n💲 *Total*\nValor de la compra: $${data.orderValue}\n🎉 *Descuento cupón: -$${data.couponValue}*\nTotal a pagar: $*${data.total}*\nMétodo de pago: ${data.paymentMethod}\n\n📝 *Detalle del pedido*\n${data.detail}\n\nGracias, que lo disfrutes 🥳`
                    break       
            }
        } else if(!hasCoupon && hasServiceCharge) {
            // ok
            switch(countryId) {
                case 1:
                    message = `Hola ${data.name}, ¡gracias por comprar en el Mercadito ${data.shopName}! 📦\n\n 🛒 Tu pedido número ${data.orderCode}, realizado el ${data.date} se ha procesado con éxito, podés retirarlo el ${data.deliveryDate}.\n\n💲 *Total*\nValor de la compra: $${data.orderValue}\nTasa de servicio: $${data.serviceCharge}\nTotal a pagar: $*${data.total}*\n Método de pago: ${data.paymentMethod}\n\n📝 *Detalle del pedido*\n${data.detail}\n\nGracias, que lo disfrutes 🥳`
                    break
                case 2:
                    message = `Hola ${data.name}, ¡gracias por comprar en el Mercadito ${data.shopName}! 📦\n\n 🛒 Tu pedido número ${data.orderCode}, realizado el ${data.date} se ha procesado con éxito, puedes retirarlo el ${data.deliveryDate}.\n\n💲 *Total*\nValor de la compra: $${data.orderValue}\nTasa de servicio: $${data.serviceCharge}\nTotal a pagar: $*${data.total}*\n Método de pago: ${data.paymentMethod}\n\n📝 *Detalle del pedido*\n${data.detail}\n\nGracias, que lo disfrutes 🥳`
                    break
                default:
                    message = `Hola ${data.name}, ¡gracias por comprar en el Mercadito ${data.shopName}! 📦\n\n 🛒 Tu pedido número ${data.orderCode}, realizado el ${data.date} se ha procesado con éxito, puedes retirarlo el ${data.deliveryDate}.\n\n💲 *Total*\nValor de la compra: $${data.orderValue}\nTasa de servicio: $${data.serviceCharge}\nTotal a pagar: $*${data.total}*\n Método de pago: ${data.paymentMethod}\n\n📝 *Detalle del pedido*\n${data.detail}\n\nGracias, que lo disfrutes 🥳`
                    break
            }
        } else {
            switch(countryId) {
                // ok
                case 1:
                    message = `Hola ${data.name}, ¡gracias por comprar en el Mercadito ${data.shopName}! 📦\n\n🛒 Tu pedido número ${data.orderCode}, realizado el ${data.date} se ha procesado con éxito, podés retirarlo el ${data.deliveryDate}.\n\n💲 *Total a pagar: $${data.total}*\nMétodo de pago: ${data.paymentMethod}\n\n📝 *Detalle del pedido*\n${data.detail}\n\nGracias, que lo disfrutes 🥳`
                    break
                case 2:
                    message = `Hola ${data.name}, ¡gracias por comprar en el Mercadito ${data.shopName}! 📦\n\n🛒 Tu pedido número ${data.orderCode}, realizado el ${data.date} se ha procesado con éxito, puedes retirarlo el ${data.deliveryDate}.\n\n💲 *Total a pagar: $${data.total}*\nMétodo de pago: ${data.paymentMethod}\n\n📝 *Detalle del pedido*\n${data.detail}\n\nGracias, que lo disfrutes 🥳`
                    break
                case 46:
                    message = `Hola ${data.name}, ¡gracias por comprar en el Mercadito ${data.shopName}! 📦\n\n🛒 Tu pedido número ${data.orderCode}, realizado el ${data.date} se ha procesado con éxito, lo recibirás el ${data.deliveryDate}.\n\n💲 *Total a pagar: $${data.total}*\nMétodo de pago: saldo en Monedero\n\nGracias, que lo disfrutes 🥳`
                    break
                case 59:
                    message = `Hola ${data.name}, ¡gracias por comprar en el Mercadito ${data.shopName}! 📦\n\n🛒 Tu pedido número ${data.orderCode}, realizado el ${data.date} se ha procesado con éxito, lo recibirás el ${data.deliveryDate}.\n\n💲 *Total a pagar: $${data.total}*\nMétodo de pago: saldo en Monedero\n\n📝 *Detalle del pedido*\n${data.detail}\n\nGracias, que lo disfrutes 🥳`
                    break
                default:
                    message = `Hola ${data.name}, ¡gracias por comprar en el Mercadito ${data.shopName}! 📦\n\n🛒 Tu pedido número ${data.orderCode}, realizado el ${data.date} se ha procesado con éxito, puedes retirarlo el ${data.deliveryDate}.\n\n💲 *Total a pagar: $${data.total}*\nMétodo de pago: ${data.paymentMethod}\n\n📝 *Detalle del pedido*\n${data.detail}\n\nGracias, que lo disfrutes 🥳`
                    break
            }
        }
    }
    if(deliveryPoint || data?.code || countryId === 46 || countryId === 95) {
        switch(countryId) {
            case 1:
                message = `✅ *CONFIRMACIÓN DEL PEDIDO #${data.orderCode}*\n\nHola ${data.name}, ¡tu pedido #${data.orderCode} realizado el ${data.date} se procesó con éxito!\n\n🔹 Total a pagar: $${formatNumber(data.total)}\n🔹 Método de pago: ${data.paymentMethod}\n\n👉 Para encontrar el detalle de tu pedido, hacé click en “tus pedidos”: https://el-mercadito.co/${data.slug}/mis-pedidos\n\n🚚 *Detalle de la entrega*\n- El pedido será entregado el *${data.deliveryDate} a las 6pm ${data?.code ? `con el código de retiro ${data.code} ` : ''}en ${data.delivery_address_main_line}* 📍\n\n📦 Contactá al punto de entrega para coordinar el retiro de tu pedido:\n- Nombre: ${deliveryPoint.name}\n- Contacto: wa.me/${deliveryPoint.phone}\n\n📞 Y ante cualquier duda escribile a nuestro Equipo de Soporte: wa.me/${customerExperiencePhone[String(data.countryId)]}`

                break
            case 2:

                message = `✅ *CONFIRMACIÓN DEL PEDIDO #${data.orderCode}*\n\nHola ${data.name}, ¡tu pedido #${data.orderCode} realizado el ${data.date} ha sido procesado con éxito!\n\n🔹 Total a pagar: $${formatNumber(data.total)}\n🔹 Método de pago: ${data.paymentMethod}\n\n👉 Para encontrar el detalle de tu pedido, haz click en “tus pedidos”: https://el-mercadito.co/${data.slug}/mis-pedidos\n\n🚚 *Detalle de la entrega*\n- El pedido será entregado el *${data.deliveryDate} a las 6pm ${data?.code ? `con el código de retiro ${data.code} ` : ''}en ${data.delivery_address_main_line}* 📍\n\n📦 Contacta al punto de entrega para coordinar el retiro de tu pedido:\n- Nombre: ${deliveryPoint.name}\n- Contacto: wa.me/${deliveryPoint.phone}\n\n📞 Y ante cualquier duda puedes escribirle a nuestro Equipo de Soporte: wa.me/${customerExperiencePhone[String(data.countryId)]}`
                break
            case 95:
                message = `Hola ${data.name}, hemos emitido el pedido ${data.orderCode} que será entregado a la Bodega ${data.shopName} con los siguientes productos / cantidades.\n\n${data.detail}\n\nAl recibir el pedido, entregale al representante de la bodega el siguiente código para confirmar la recepción del pedido.\n\n*Código de verificación de entrega:* ${data.code}`
                break
            default:
                message = `✅ *CONFIRMACIÓN DEL PEDIDO #${data.orderCode}*\n\nHola ${data.name}, ¡tu pedido #${data.orderCode} realizado el ${data.date} ha sido procesado con éxito!\n\n🔹 Total a pagar: $${formatNumber(data.total)}\n🔹 Método de pago: saldo en Monedero\n\n👉 Para encontrar el detalle de tu pedido, haz click en “tus pedidos”: https://el-mercadito.co/${data.slug}/mis-pedidos\n\n🚚 *Detalle de la entrega*\n- El pedido será entregado el *${data.deliveryDate} a las 6pm ${data?.code ? `con el código de retiro ${data.code} ` : ''}en ${data.delivery_address_main_line}* 📍\n\n📦 Contacta al punto de entrega para coordinar el retiro de tu pedido:\n- Nombre: ${data.shopName}\n- Contacto: wa.me/${data.shopPhone}`
                break
        }
    }

    return message
}


export const getMessageToClient = async (order, cart, datosComprador, communityLeader, deliveryPoint, slug) =>  {
    const detail = getDetail(cart, order.lines)
    const data = parseData(order, datosComprador, communityLeader, detail, slug)
    if(deliveryPoint) {
        const communityLeaderId = deliveryPoint.community_leader_id
        const dataCommunityLeader = await getcommunityleader(communityLeaderId)
        const phone = dataCommunityLeader.phone
        deliveryPoint.phone = phone
    }
    const sendDeliveryCode = featureMarkAsDelivered(order.country_id, deliveryPoint?.id ?? order.shop_id)
    if(sendDeliveryCode) {
        const body = { "customer_id": order.customer_id };
        const code = await getcode(order.id, order.country_id, body)
        data.code = code?.code ?? null
    }
    const hasCoupon = order.coupon_id
    const hasServiceCharge = Number(order?.service_charge_value.value) > 0 ?? null
    const message = getMessageOrderConfirmationToClient(data, order.country_id, hasCoupon, hasServiceCharge, deliveryPoint ?? sendDeliveryCode, communityLeader.name, communityLeader.phone)
    const messageLength = [...message].length
    if(messageLength > 1550) {
        const difference = messageLength - 1550
        let newDetail = detail.slice(0, difference * -1) + '...[más productos]'
        const newData = parseData(order, datosComprador, communityLeader, newDetail)
        const newMessage = getMessageOrderConfirmationToClient(newData, order.country_id, hasCoupon, hasServiceCharge)
        return newMessage
    }
    return message
}
