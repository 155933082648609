import { fliptFeatureFlags } from './helpers';

export default async function getFeatureFlags(countryId) {
    const response = await fetch(`/api/flipt`, {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify({
            country_id: countryId,
            userToken: localStorage.getItem('customer_id') || localStorage.getItem('anonymousId'),
            flags: fliptFeatureFlags,
        }),
      });
      const data = await response.json();
      return data;
}
