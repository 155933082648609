import { track } from 'services/gtm';
import { totalDiscountNumber } from 'services/producthelpers';

export function trackDateSelector(action, date_position, date_selected) {
  track(action, {
    date_position,
    date_selected,
  });
}

export function trackWalletSwitch(checked) {
  track('wallet_switch', {
    checked,
  });
}

export function trackBeginCheckout(currency, total, products, coupon) {
  track('begin_checkout', {
    coupon,
    ecommerce: {
      currency: currency,
      value: total,
      items: products.map((item, index) => ({
        item_name: item.nombre,
        item_id: item.productid,
        price: item.precio,
        index,
        discount: totalDiscountNumber(item?.datos_producto),
        currency: currency,
        quantity: item.cantidad,
        item_category: item.categoria,
        item_category2: Number(item.datos_producto.category_id),
      })),
    },
  });
}

export function trackAddShippingInfo(currency, total, products, coupon) {
  track('add_shipping_info', {
    coupon,
    ecommerce: {
      currency: currency,
      value: total,
      items: products.map((item, index) => ({
        item_name: item.nombre,
        item_id: item.productid,
        price: item.precio,
        index,
        discount: totalDiscountNumber(item?.datos_producto),
        currency: currency,
        quantity: item.cantidad,
        item_category: item.categoria,
        item_category2: Number(item.datos_producto.category_id),
      })),
    },
  });
}

export function trackAddPaymentInfo(currency, total, payment_type, products, coupon) {
  track('add_payment_info', {
    coupon,
    ecommerce: {
      currency: currency,
      value: total,
      payment_type: payment_type,
      items: products?.map((item, index) => ({
        item_name: item.nombre,
        item_id: item.productid,
        price: item.price,
        index,
        discount: totalDiscountNumber(item?.datos_producto),
        currency: currency,
        quantity: item.cantidad,
        item_category: item.categoria,
        item_category2: Number(item.datos_producto.category_id),
      })),
    },
  });
}

export function trackCouponEntryStarted(currency, total, products) {
  track('coupon_entry_started', {
    ecommerce: {
      currency: currency,
      value: total,
      items: products.map((item, index) => ({
        item_name: item.nombre,
        item_id: item.productid,
        price: item.precio,
        index,
        discount: totalDiscountNumber(item?.datos_producto),
        currency: currency,
        quantity: item.cantidad,
        item_category: item.categoria,
        item_category2: Number(item.datos_producto.category_id),
      })),
    },
  });
}

export function trackCouponValidated(currency, total, products, coupon) {
  track('coupon_validated', {
    coupon,
    ecommerce: {
      currency: currency,
      value: total,
      items: products.map((item, index) => ({
        item_name: item.nombre,
        item_id: item.productid,
        price: item.precio,
        index,
        discount: totalDiscountNumber(item?.datos_producto),
        currency: currency,
        quantity: item.cantidad,
        item_category: item.categoria,
        item_category2: Number(item.datos_producto.category_id),
      })),
    },
  });
}

export function trackCouponRejected(currency, total, products, coupon, error_text, error_code) {
  track('coupon_rejected', {
    error_text,
    error_code,
    coupon,
    ecommerce: {
      currency: currency,
      value: total,
      items: products.map((item, index) => ({
        item_name: item.nombre,
        item_id: item.productid,
        price: item.precio,
        index,
        discount: totalDiscountNumber(item?.datos_producto),
        currency: currency,
        quantity: item.cantidad,
        item_category: item.categoria,
        item_category2: Number(item.datos_producto.category_id),
      })),
    },
  });
}

export function trackCouponRemoved(currency, total, products, coupon) {
  track('coupon_removed', {
    coupon,
    ecommerce: {
      currency: currency,
      value: total,
      items: products.map((item, index) => ({
        item_name: item.nombre,
        item_id: item.productid,
        price: item.precio,
        index,
        discount: totalDiscountNumber(item?.datos_producto),
        currency: currency,
        quantity: item.cantidad,
        item_category: item.categoria,
        item_category2: Number(item.datos_producto.category_id),
      })),
    },
  });
}

export function trackPurchase(currency, total, transaction_id, products, coupon) {
  track('purchase', {
    coupon,
    ecommerce: {
      currency: currency,
      transaction_id: transaction_id,
      value: total,
      items: products.map((item, index) => ({
        item_name: item.nombre,
        item_id: item.productid,
        price: item.precio,
        index,
        discount: totalDiscountNumber(item?.datos_producto),
        currency: currency,
        quantity: item.cantidad,
        item_category: item.categoria,
        item_category2: Number(item.datos_producto.category_id),
      })),
    },
    eventType: 'conversion'
  });
}
export function trackOrderNotSent(currency, total, products, coupon, error_text, error_code) {
  track('order_not_sent', {
    error_text,
    error_code,
    coupon,
    ecommerce: {
      currency: currency,
      value: total,
      items: products.map((item, index) => ({
        item_name: item.nombre,
        item_id: item.productid,
        price: item.precio,
        index,
        discount: totalDiscountNumber(item?.datos_producto),
        currency: currency,
        quantity: item.cantidad,
        item_category: item.categoria,
        item_category2: Number(item.datos_producto.category_id),
      })),
    },
  });
}
