import '../styles/globals.css';
import '../styles/StylesBulma.scss';
import { CarritoProvider } from 'context/Context';
import { useEffect, useState } from 'react';
import { withRouter } from 'next/router';
import { trackpage } from 'services/gtm';
import Head from 'next/head';
import { activeCountries, countriesInSelector, localeCodeByCountryCode } from 'services/helpers';
import useTranslations from 'translations/useTranslations';
import { IntlProvider } from 'react-intl';
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import Loader from 'componentes/Loader';
import  getDatosTienda  from 'services/getdatostienda';

/* Color themes */
import { ThemeProvider } from 'styled-components';
import { themeMercadito } from 'themes/mercadito';
import { themeWFP } from 'themes/wfp';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1, // Reducir el número de reintentos en caso de errores
      cacheTime: 1000 * 60 * 5, // Reduce el tiempo de caché si es necesario
      staleTime: 1000 * 30, // Mantener los datos frescos durante menos tiempo si la red es lenta
    },
  },
});

function MyApp({ Component, pageProps, router }) {
  const route = router?.state?.query?.route;
  const demo = router?.state?.query?.demo || false;
  const locale = demo || localeCodeByCountryCode[route] || 'es-MX'
  const asPath = router?.state?.asPath || 'unknown';
  const { messages } = useTranslations(locale);

  //this useState is responsible for changing the theme of the app
  const [theme, setTheme] = useState(null);

  const themeByCountry = {
    46: themeWFP,
    59: themeWFP,
  };

  useEffect(() => {
    route && asPath && trackpage({ title: route, url: asPath, guestCheckout: true });
    route && getDatosTienda(route).then((data) => {
      setTheme(themeByCountry[data?.data?.country_id] ?? themeMercadito);
    });
    !route && setTheme(themeMercadito);
  }, [route, asPath]);

  if(messages && theme) return (
    <>
      <Head>
        <html lang="es-MX"/>
        <title>{!activeCountries.includes(route) ? (countriesInSelector.includes(route) ? 'Mercadito' : route ?? 'Mercadito') : 'Mercadito - ofertas siempre'}</title>
        <meta name="description" content={!activeCountries.includes(route) ? (countriesInSelector.includes(route) ? 'Las mejores ofertas en productos frescos y de calidad. Compra online y ahorra en cada compra. ¡Mercadito te sirve!. Ahorros y confianza sin moverte de tu casa.' : route ? `¡En ${route} tenemos las mejores ofertas!` : 'Las mejores ofertas en productos frescos y de calidad. Compra online y ahorra en cada compra. ¡Mercadito te sirve!. Ahorros y confianza sin moverte de tu casa.') : 'Abrí gratis tu tienda virtual y generá ingresos extra todas las semanas.'} />
        <meta name="google" content="notranslate" />
        <meta httpEquiv="content-language" content="es-MX"/>
      </Head>
      <QueryClientProvider client={queryClient}>
        <CarritoProvider>
          <IntlProvider messages={messages} locale={locale} defaultLocale="es-MX" textComponent={'span'}>
            <ThemeProvider theme={theme}>
              <Component {...pageProps} translate="no" />
            </ThemeProvider>
          </IntlProvider>
        </CarritoProvider>
      </QueryClientProvider>
    </>
  );
  return <Loader />
}

export default withRouter(MyApp);
